import React from "react";
import MenuItem from './menu-item';
import {Columns, Column} from 'bloomer'
import PropTypes from "prop-types";

const Component = ({urlLinks,linkRefs}) => {
  return (
    Array.isArray(urlLinks) && urlLinks.length
    && <div className="menu-container">
      <div className='row '>

          <div  className=' menu has-text-weight-light is-9 '>
            <ul>
              {
                urlLinks.map((link,key)=>{
                  const uniqueKey = `${link.id}-${key}`;
                 return link &&
                   link.name &&
                   <MenuItem
                     key={`menu-${uniqueKey}-${key}`}
                     link={link}
                     linkRefs={linkRefs}
                   />
              })
              }
            </ul>
          </div>

      </div>
    </div>
  );
};

Component.propTypes = {
  urlLinks: PropTypes.array,
  linkRefs:PropTypes.object
};
Component.defaultProps = {};

export default Component;
