import React from "react";
import {MediaContent,Media,Image} from 'bloomer'
import {Link} from 'gatsby';

const Component = (props) => {
  return (
      <Link to='#'>
        <img  src="/img/emerald-logo2.png" />
      </Link>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
