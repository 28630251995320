import React, {Component} from "react";
import {StaticQuery,graphql} from 'gatsby'
import Menu from './mega-menu'
import PropTypes from "prop-types";


const StaticMenu = ({linkRefs}) => {
  return <StaticQuery
    query={graphql `query MenuQuery {
   allMarkdownRemark(filter:{
    frontmatter:{templateKey:{eq:"site-menu"}}
  },sort:{fields:[frontmatter___pageIndex],order:ASC}){
    edges{
      node{
        id
        frontmatter{
          name
          url
          children{
            name
            url
            children{
              name
              url
            }
          }
        }
      }
    }
  }
}`}
    render={(data)=>{
      const result = data.allMarkdownRemark.edges;
      const urlLinks = result.map(edge=>{
        const urlLink = edge.node.frontmatter
        return {id:edge.node.id,...urlLink}
      });
      return <Menu linkRefs={linkRefs} urlLinks={urlLinks}/>
    }}
  />
};

StaticMenu.propTypes = {
  linkRefs:PropTypes.object
};
StaticMenu.defaultProps = {};



class NavMenu extends Component {
  render() {
    const {links,context} = this.props;
    const linkRefs = context && context.linkRefs;
    return (Array.isArray(links) &&
      <Menu
        urlLinks={links}
        linkRefs={linkRefs}
      />) || <StaticMenu linkRefs={linkRefs}/>
  }
};

NavMenu.propTypes = {
  links:PropTypes.array,
  context: PropTypes.object,
};
NavMenu.defaultProps = {};

export default NavMenu;
