import React, { Component } from "react";
import Helmet from 'react-helmet'
import {Title,Tabs,Tab,TabList,TabLink,Icon} from 'bloomer'
import Footer from '../components/footer'
import Top from '../components/header/top'
import Nav from '../components/header/nav'
import './style/all.sass'
import '../../static/fonts/ionicons.eot';
import '../../static/fonts/ionicons.svg';
import '../../static/fonts/ionicons.ttf';
import '../../static/fonts/ionicons.woff';
import './style/ionicons.min.css';
import PropTypes from "prop-types";
import {Navbar,NavbarMenu,NavbarBurger,NavbarBrand,NavbarItem} from 'bloomer'

class Layout extends Component {
  render() {
   const { children,urlLinks }=this.props
    return (
      <div>
        <Helmet>
          title="Home | EmeraldField School"
          <meta name="keywords" content="Emeraldfield college, emeraldfield,emeraldfield schools calabar,secondary
          schools in calabar, college, school, emeraldfield school,emeraldfield schools"/>
          <meta name="description" content="EmeraldField Schools was founded in 2014 by Mr. Gerald Ada working with Mrs.
          Bridget Amgbah and Ms. Jane Ndukwe as pioneer staff.
          The school is located in the serene and quiet business district of Ekorinim in Calabar,
          the capital city of Cross River State, Nigeria. Cross River State, located in South South region of Nigeria,"/>
          <meta name="subject" content="Emeraldfield school"/>
          <meta name="copyright"content="Emeraldfield school"/>
        </Helmet>
        <Navbar className='logo is-primary is-hidden-desktop'>
          <NavbarBrand>
            <NavbarItem>
             <Title className='has-text-white' isSize={5}> EmeraldField College</Title>
            </NavbarItem>
            <NavbarBurger
              className="mobile-menu"
            />
          </NavbarBrand>
        </Navbar>
        <Top  className="is-hidden-touch" />
        <Nav links={urlLinks}/>
        <div id="page">
          {children}
        </div>
        <Footer/>
            <Navbar className='is-hidden is-fixed-bottom is-primary'>
            <Tabs className='is-centered is-boxed' >
              <TabList>
                <Tab>
                  <TabLink>
                    <Icon isSize='small'><span className='ion ion-home' aria-hidden='true' /></Icon>

                    <span>Home</span>
                  </TabLink>
                </Tab>
                <Tab>
                  <TabLink>
                    <Icon isSize='small'><span className='ion ion-calculator' aria-hidden='true' /></Icon>

                    <span>Admissions</span>
                  </TabLink>
                </Tab>
                <Tab>
                  <TabLink>
                    <Icon isSize='small'><span className='ion ion-calendar' aria-hidden='true' /></Icon>

                    <span>Calendar</span>
                  </TabLink>
                </Tab>
                <Tab>
                  <TabLink>
                    <Icon isSize='small'><span className='ion ion-chatbubbles' aria-hidden='true' /></Icon>
                    <span>Contact</span>
                  </TabLink>
                </Tab>
              </TabList>
            </Tabs>
            </Navbar>
      </div>
    );
  }
}

Layout.propTypes = {
  isHomePage:PropTypes.bool,
  urlLinks:PropTypes.array,
};
Layout.defaultProps={

}

export default Layout;

