import React from "react";
import {StaticQuery,graphql} from 'gatsby'
import Nav from './nav-item';
import PropTypes from "prop-types";

const prepareLinkReferences = (data)=>{
 const items = data.allSitePage.edges.map(edge=>edge.node);
 return items.reduce((acc,item)=>{
   return Object.assign(acc,{[item.context.name]:item.path})
 },{})
};

const Component = ({className}) => {
  return (
    <StaticQuery
      query={graphql`{
  allSitePage(filter:{context:{
    templateKey:{in:["site-page"]}
  }}){
    edges{
      node{
        path
        context{
          templateKey
          name
        }
      }
    }
  }
}`}
      render={(data)=>{
          const context = {linkRefs: prepareLinkReferences(data)};
        return (<div className={className}><Nav context={context}/></div>)
      }}
    />
  );
};

Component.propTypes = {
  className:PropTypes.string
};
Component.defaultProps = {};

export default Component;
