import React from "react";
import {Link} from 'gatsby';
import PropTypes from "prop-types";
import ShortUniqueId from 'short-unique-id';
const uuid = new ShortUniqueId()



const getLinkRef = (linkRefs,link)=>{
  return (linkRefs && linkRefs[link]) || link
}


const getListItem = (link,linkRefs)=>{
  const id = uuid.randomUUID(8);
  return <li key={`menu-item-${id}`}>
    <Link
      className='is-uppercase'
      to={getLinkRef(linkRefs,link.url) || '#'}
    >
      {link.name}
    </Link>
    {
      ((Array.isArray(link.children) &&
      link.children.length &&
      <ul id={`sub-${id}`}>
        {link.children.map((child)=>getListItem(child,linkRefs))}
      </ul>)|| null)
    }
  </li>;
}


const Component = ({link,linkRefs}) => {
    return link
      && link.name
      && getListItem(link,linkRefs)
};

Component.propTypes = {
  link:PropTypes.shape({
    name:PropTypes.string,
    url:PropTypes.string,
  }),
  linkRefs:PropTypes.object
};
Component.defaultProps = {
  linkRefs:{}
};

export default Component;
