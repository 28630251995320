import React from "react";
import {Link} from 'gatsby'
import {Field,Input,Control,Columns,Column,Icon} from 'bloomer'
import Logo from './logo'
import PropTypes from 'prop-types'

const Component = ({className}) => {
  return (
      <div className={className} id='header-wrap'>
        <div className='top-section'>
          <div className='left  '>
            <Link className=' has-text-white parent-portal-link' to={'#'}>
              Parent Portal <Icon isSize="medium"  className='ion ion-ios-contact-outline'/>
            </Link>
          </div>
          <div className='right'>
            <Field style={{width:'50%',marginLeft:'28%',padding:5}}>
              <Control >
                <Input isSize={'small'} type='text' placeholder='search'/>
              </Control>
            </Field>
          </div>
        </div>
        <div className='row'>
            <Columns isCentered  >
              <Column  className='is-12-desktop is-12-tablet'>
                  <div id='header'>
                     <div id="header-content">
                       <div className='logo' id="logo">
                          <Logo/>
                       </div>
                     </div>
                  </div>
              </Column>
            </Columns>
        </div>
      </div>
  );
};

Component.propTypes = {
  className:PropTypes.string
};
Component.defaultProps = {};

export default Component;
