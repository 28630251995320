import React from "react";
import {Columns,Column,Subtitle} from 'bloomer'
import {Link} from 'gatsby'
// import PropTypes from "prop-types";

const Component = (props) => {
  return (
    <div id='footer'>
      <div className='row'>
        <Columns>
          <Column className='is-8'>
            <Subtitle tag='p'>
              Copyrights &#x24B8; 2018 EmeraldField College
              <Link to={'#'}>
                &nbsp;&nbsp; | &nbsp;&nbsp;Privacy notice&nbsp;&nbsp; |
              </Link>
              <Link to={"#"}>
                &nbsp;&nbsp; Website Ts&C's&nbsp;&nbsp; |
              </Link>
              <Link to={"#"}>
                &nbsp;&nbsp; Sitemap
              </Link>
            </Subtitle>

          </Column>
          <Column className='is-4'>

          </Column>
        </Columns>
      </div>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
